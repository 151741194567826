<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Contratos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div
                class="card-header pt-2 pb-2"
                v-if="$store.getters.can('cs.contratosInternos.create')"
              >
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-reporte-tepContratoInt"
                        @click="limpiarModal()"
                        v-if="
                          $store.getters.can(
                            'cs.contratosInternos.excelCsContratosInt'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  id="contratos"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Contrato
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.no_contrato"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label> Empresa </label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        <label> Bloques </label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_bloque"
                          placeholder="Bloques"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="bloques"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>Observaciones</th>
                      <th style="width: 150px">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contrato in contratos.data" :key="contrato.id">
                      <td>{{ contrato.no_contrato }}</td>
                      <td>{{ contrato.empresa.razon_social }}</td>
                      <td class="text-center">
                        <div>
                          <span
                            class="badge badge-info m-1 p-2"
                            v-for="bloq in contrato.bloques"
                            :key="bloq.id"
                          >
                            {{ bloq.nombre }}
                          </span>
                        </div>
                      </td>
                      <td class="text-wrap">
                        <small>{{ contrato.observaciones }}</small>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            contrato.estado == 1
                              ? 'badge-warning'
                              : 'badge-success',
                          ]"
                        >
                          {{ contrato.estado == 1 ? "Borrador" : "Definitivo" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('cs.contratosInternos.show')
                            "
                            @click="edit(contrato)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <!-- <button
                            type="button"
                            class="btn btn-sm bg-olive"
                            data-toggle="modal"
                            data-target="#modal_otrosi"
                            v-if="
                              $store.getters.can(
                                'cs.contratosInternos.edit'
                              ) && contrato.estado == 2
                            "
                            @click="llenarModal(contrato)"
                          >
                            <i class="fas fa-file-signature"></i>
                          </button> -->
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can(
                                'cs.contratosInternos.delete'
                              ) && contrato.estado == 1
                            "
                            @click="destroy(contrato.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="contratos.total">
                  <p>
                    Mostrando del <b>{{ contratos.from }}</b> al
                    <b>{{ contratos.to }}</b> de un total:
                    <b>{{ contratos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="contratos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- <div class="modal fade" id="modal_otrosi">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Otrosí</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-2">
                <label for="fecha_inicial"># Otrosí</label>
                <input
                  type="text"
                  maxlength="30"
                  class="form-control form-control-sm"
                  id="no_otrosi"
                  v-model="form.no_otrosi"
                  :class="
                    $v.form.no_otrosi.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="tipo_contrato">Tipo de Contrato</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_contrato"
                  :class="
                    $v.form.tipo_contrato.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_contrato in listasForms.tipos_contrato"
                    :key="tipo_contrato.numeracion"
                    :value="tipo_contrato.numeracion"
                  >
                    {{ tipo_contrato.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-4">
                <label for="objeto_contrato">Objeto de Contrato</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.objeto_contrato"
                  :class="
                    $v.form.objeto_contrato.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="objeto_contrato in listasForms.objetos_contrato"
                    :key="objeto_contrato.numeracion"
                    :value="objeto_contrato.numeracion"
                  >
                    {{ objeto_contrato.descripcion }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-2">
                <label for="fecha_ini">Fecha Inicio</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_ini"
                  v-model="form.fecha_ini"
                  :class="
                    $v.form.fecha_ini.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="validaFechas"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="fecha_fin">Fecha Fin</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="fecha_fin"
                  v-model="form.fecha_fin"
                  :class="
                    $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @input="validaFechas"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="valor">Valor</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="valor"
                  v-model="form.valor"
                  :class="$v.form.valor.$invalid ? 'is-invalid' : 'is-valid'"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="fecha_ini">Vigencia Póliza</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  id="vigencia_poliza"
                  v-model="form.vigencia_poliza"
                  :class="
                    $v.form.vigencia_poliza.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="pernocte">Pernocte <small>(Valor)</small></label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="pernocte"
                  v-model="form.pernocte"
                  :class="$v.form.pernocte.$invalid ? 'is-invalid' : 'is-valid'"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="peajes">Límite Peajes</label>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="peajes"
                  v-model="form.peajes"
                  :class="$v.form.peajes.$invalid ? 'is-invalid' : 'is-valid'"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-3 text-center">
                <label for="combustible">Devuelve Combustible</label>
                <input
                  type="checkbox"
                  class="form-control form-control-sm p-0"
                  v-model="form.combustible"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                />
              </div>
              <div class="form-group col-md-5">
                <label for="observaciones">Observaciones</label>
                <br />
                <textarea
                  vtype="date"
                  class="form-control form-control-sm"
                  id="fecha_final"
                  v-model="form.observaciones"
                  rows="2"
                  :disabled="!$store.getters.can('cs.contratosInternos.edit')"
                ></textarea>
              </div>
              <div class="form-group col-md-5" v-if="form.id">
                <label for="link_documento"
                  >Copia contrato <sup>(PDF)</sup></label
                >
                <div
                  class="form-group"
                  v-if="form.link_documento == null && estado == 1"
                >
                  <input
                    type="file"
                    id="link_documento"
                    ref="link_documento"
                    class="form-control-file"
                    accept=".pdf, .PDF"
                    @change="elegirDirectorio()"
                    :disabled="
                      !$store.getters.can('cs.contratosInternos.edit')
                    "
                  />
                  <br />
                  <button
                    id="guardar_documento"
                    type="button"
                    @click="saveDocumento()"
                    class="btn btn-success"
                    data-html="true"
                    title="Guardar Documento"
                    data-toggle="tooltip"
                    :disabled="
                      !$store.getters.can('cs.contratosInternos.edit') ||
                        !link_documento
                    "
                  >
                    <i class="fa fa-save"></i>
                  </button>
                </div>
                <div class="form-group">
                  <button
                    class="btn btn-secondary"
                    @click="descargarDocumento()"
                    id="BotonDescargar"
                    v-if="form.link_documento"
                    href=""
                  >
                    Descargar
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    v-if="
                      $store.getters.can('cs.contratosInternos.delete') &&
                        form.link_documento
                    "
                    :disabled="
                      form.estado == 2 ||
                        !$store.getters.can('cs.contratosInternos.edit')
                    "
                    @click="destroyContrato"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('cs.contratosInternos.edit') &&
                  !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
          <div class="modal-body table responsive">
            <div class="row">
              <table
                class="
                  table table-bordered table-striped table-hover
                  text-nowrap
                  table-sm
                "
              >
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center"># Otrosí</th>
                    <th class="text-center">Tipo Contrato</th>
                    <th class="text-center">Objeto Contrato</th>
                    <th class="text-center">fecha_ini</th>
                    <th class="text-center">fecha_fin</th>
                    <th class="text-center">Valor</th>
                    <th class="text-center">Pernocte</th>
                    <th class="text-center">Combustible</th>
                    <th class="text-center">Peajes</th>
                    <th class="text-center">Vigencia Póliza</th>
                    <th class="text-center">Observaciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="otrosi in otrosis" :key="otrosi.id">
                    <td>{{ otrosi.no_otrosi }}</td>
                    <td>{{ otrosi.nTipoContrato }}</td>
                    <td>{{ otrosi.nObjetoContrato }}</td>
                    <td class="text-center">{{ otrosi.fecha_ini }}</td>
                    <td class="text-center">{{ otrosi.fecha_fin }}</td>
                    <td class="text-right">{{ otrosi.valor }}</td>
                    <td class="text-right">{{ otrosi.pernocte }}</td>
                    <td class="text-center">
                      <span
                        class="badge"
                        :class="otrosi.combustible ? 'bg-success' : 'bg-danger'"
                      >
                        {{ otrosi.combustible ? "Si" : "No" }}
                      </span>
                    </td>
                    <td class="text-right">{{ otrosi.peajes }}</td>
                    <td class="text-center">{{ otrosi.vigencia_poliza }}</td>
                    <td class="text-wrap">
                      <small>{{ otrosi.observaciones }}</small>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <CsContratoInternoExport ref="CsContratoInternoExport" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import CsContratoInternoExport from "./CsContratoInternoExport";

export default {
  name: "CsContratoInternoIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    CsContratoInternoExport,
  },

  data() {
    return {
      cargando: false,
      file: "",
      filtros: {
        no_contrato: null,
        empresa_id: null,
        estado: null,
      },
      contratos: {},
      empresas: [],
      slct_empresa: [],
      bloques: [],
      slct_bloque: [],
      otrosis: [],
      listasForms: {
        estados: [],
        tipos_contrato: [],
        objetos_contrato: [],
      },
      form: {
        tep_contrato_interno_id: null,
        no_otrosi: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        pernocte: null,
        combustible: false,
        peajes: null,
      },
    };
  },

  validations: {
    form: {
      no_otrosi: {
        required,
      },
      tipo_contrato: {
        required,
      },
      objeto_contrato: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      vigencia_poliza: {
        required,
      },
      valor: {
        required,
      },
      pernocte: {
        required,
      },
      combustible: {
        required,
      },
      peajes: {
        required,
      },
    },
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      const me = this;

      this.filtros.empresa_id = null;
      if (this.slct_empresa) {
        this.filtros.empresa_id = this.slct_empresa.id;
      }
      this.filtros.bloque_id = null;
      if (this.slct_bloque) {
        this.filtros.bloque_id = this.slct_bloque.id;
      }
      axios
        .get("/api/cs/contratosInternos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.contratos = response.data;
        })
        .catch(function (error) {
          me.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    destroy(idContrato) {
      this.$swal({
        title: "Está seguro de eliminar este Contrato?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      })
        .then((result) => {
          if (result.value) {
            this.cargando = true;
            axios.delete("/api/cs/contratosInternos/" + idContrato).then(() => {
              this.cargando = false;
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó el Contrato exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
          }
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: { linea_negocio_id: [4] },
          })
          .then(function (response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarBloques() {
      let me = this;
      var url = "api/admin/bloques/lista";
      axios
        .get(url)
        .then(function (response) {
          let respuesta = response.data;
          me.bloques = respuesta;
        })
        .catch(function (error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    create() {
      return this.$router.push({
        name: "/Cs/ContratoInternoForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(contrato) {
      return this.$router.push({
        name: "/Cs/ContratoInternoForm",
        params: { accion: "Editar", data_edit: contrato, id: contrato.id },
      });
    },

    /***** Funciones para los otrosí */
    llenarModal(contrato) {
      this.form = {
        tep_contrato_interno_id: contrato.id,
        empresa_id: contrato.empresa_id,
        no_otrosi: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        pernocte: null,
        combustible: false,
        peajes: null,
      };

      this.otrosis = contrato.otrosis;
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/cs/contratosInternos/otrosis",
          data: this.form,
        })
          .then(() => {
            this.getIndex();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "El otrosí se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarModal() {
      this.$refs.CsContratoInternoExport.form = {};
    },
    /***** Fin - Funciones para los otrosí */
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.buscarBloques();
  },
};
</script>
